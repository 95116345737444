import {
  Button,
  Card,
  Col,
  DatePicker,
  Form,
  Row,
  Select,
  Space,
  Table,
} from 'antd'
import { SorterResult } from 'antd/es/table/interface'
import axios from 'axios'
import dayjs from 'dayjs'
import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useQuery } from 'react-query'
import { Link, useNavigate } from 'react-router-dom'

import { EyeOutlined, FileExcelOutlined } from '@ant-design/icons'

import HeaderCard from '../../components/common/header-card'
import { IPaginatedList } from '../../models/paginated-list'
import {
  DATE_FORMAT,
  DATE_TIME_FORMAT,
  DEFAULT_PAGE_SIZE,
  DEFAULT_SORT_FUNCTION,
  INITIAL_PAGE,
} from '../../utils/constants'

import type { ColumnsType } from 'antd/es/table'
import { IOrder, OrderStatus } from '../../models/order'
import OrderStatusTag from '../../components/enums/order-status-tag'
import OrderPaymentMethodTag from '../../components/enums/order-payment-method-tag'
import { Currency, ISort } from '../../models/shared'
import fileDownload from 'js-file-download'
import { useKeycloak } from '@react-keycloak/web'
import { P_SHOW_ORDERS } from '../../utils/rbac/permissions'

const OrdersPage: React.FC = () => {
  const {
    keycloak: { hasRealmRole },
  } = useKeycloak()

  const navigate = useNavigate()

  if (!hasRealmRole(P_SHOW_ORDERS)) {
    navigate('/403')
  }

  const { t } = useTranslation()

  const [page, setPage] = useState(INITIAL_PAGE)
  const [pageSize, setPageSize] = useState(DEFAULT_PAGE_SIZE)
  const [search, setSearch] = useState<string | undefined>()
  const [filter, setFilter] = useState<any>({})
  const [sort, setSort] = useState<ISort | undefined>()

  const query = useQuery({
    queryKey: ['orders', page, pageSize, search, filter, sort],
    queryFn: async () => {
      return axios.get<IPaginatedList<IOrder>>('/order', {
        params: {
          page,
          pageSize,
          search,
          ...filter,
          sortKey: sort?.name,
          sortDirection: sort?.direction,
        },
      })
    },
  })

  const columns: ColumnsType<IOrder> = [
    {
      title: t('order.sequence'),
      render: (order) => (
        <Link to={`/order/${order.id}`}>{order.sequence}</Link>
      ),
      key: 'sequence',
      sorter: DEFAULT_SORT_FUNCTION,
    },
    {
      title: t('order.email'),
      dataIndex: 'email',
    },
    {
      title: t('order.status'),
      dataIndex: 'status',
      render: (status) => <OrderStatusTag status={status} />,
    },
    {
      title: t('order.total_price'),
      dataIndex: 'totalPrice',
      render: (_, record) => `${record.totalPrice} ${record.currency}`,
    },
    {
      title: t('order.payment_method'),
      dataIndex: 'paymentMethod',
      render: (method) => <OrderPaymentMethodTag method={method} />,
    },
    {
      title: t('general.created_at'),
      dataIndex: 'createdAt',
      key: 'createdAt',
      render: (dateTime) => (
        <div style={{ width: 150 }}>
          {dayjs(dateTime).format(DATE_TIME_FORMAT)}
        </div>
      ),
      sorter: DEFAULT_SORT_FUNCTION,
    },
    {
      title: t('general.updated_at'),
      dataIndex: 'updatedAt',
      key: 'updatedAt',
      render: (dateTime) => (
        <div style={{ width: 150 }}>
          {dayjs(dateTime).format(DATE_TIME_FORMAT)}
        </div>
      ),
      sorter: DEFAULT_SORT_FUNCTION,
    },
    {
      title: t('general.actions'),
      render: (_, record) => (
        <Space>
          <Button
            type="link"
            icon={<EyeOutlined />}
            onClick={() => navigate(`/order/${record.id}`)}
          >
            {t('general.show')}
          </Button>
        </Space>
      ),
    },
  ]

  return (
    <>
      <HeaderCard
        title={t('order.label')}
        onSearch={(value) => {
          setSearch(value.length === 0 ? undefined : value)
        }}
        trailing={[
          <Button
            icon={<FileExcelOutlined />}
            onClick={async () => {
              const response = await axios.get('/order/generate-excel-file', {
                params: {
                  search,
                  ...filter,
                  sortKey: sort?.name,
                  sortDirection: sort?.direction,
                },
                responseType: 'blob',
              })
              fileDownload(response.data, 'orders.xlsx')
            }}
          >
            {t('general.export_to_excel')}
          </Button>,
        ]}
        onFilterChange={(data) => {
          setFilter({
            status: data.status,
            currency: data.currency,
            fromDate: data.createdAt
              ? data.createdAt[0]
                ? dayjs(data.createdAt[0]).format(DATE_FORMAT)
                : undefined
              : undefined,
            toDate: data.createdAt
              ? data.createdAt[1]
                ? dayjs(data.createdAt[1]).format(DATE_FORMAT)
                : undefined
              : undefined,
          })
        }}
        filters={
          <Row style={{ padding: '0 1rem' }} gutter={16}>
            <Col span={6}>
              <Form.Item name="status" label={t('order.status')}>
                <Select
                  allowClear
                  options={[
                    {
                      label: t('order.status_pending'),
                      value: OrderStatus.PENDING,
                    },
                    {
                      label: t('order.status_payment_in_progress'),
                      value: OrderStatus.PAYMENT_IN_PROGRESS,
                    },
                    {
                      label: t('order.status_partially_paid'),
                      value: OrderStatus.PARTIALLY_PAID,
                    },
                    {
                      label: t('order.status_paid'),
                      value: OrderStatus.PAID,
                    },
                    {
                      label: t('order.status_processed'),
                      value: OrderStatus.PROCESSED,
                    },
                    {
                      label: t('order.status_refunded'),
                      value: OrderStatus.REFUNDED,
                    },
                  ]}
                />
              </Form.Item>
            </Col>
            <Col span={6}>
              <Form.Item name="currency" label={t('order.currency')}>
                <Select
                  allowClear
                  options={[
                    {
                      label: 'USD',
                      value: Currency.USD,
                    },
                  ]}
                />
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item name="createdAt" label={t('general.created_at')}>
                <DatePicker.RangePicker style={{ width: '100%' }} />
              </Form.Item>
            </Col>
          </Row>
        }
      />
      <Card bordered={false} bodyStyle={{ padding: 0 }}>
        <Table
          loading={query.isFetching}
          columns={columns}
          dataSource={query.data?.data.data}
          rowKey={(record) => record.id}
          style={{ overflowX: 'scroll' }}
          onChange={(_, __, sorter, ___) => {
            const { columnKey, order } = sorter as SorterResult<IOrder>

            if (!order) {
              setSort(undefined)
            } else {
              setSort({
                name: columnKey as string,
                direction: order === 'ascend' ? 'ASC' : 'DESC',
              })
            }
          }}
          pagination={{
            current: page,
            pageSize: pageSize,
            pageSizeOptions: [5, 10, 20, 50, 100],
            showSizeChanger: true,
            total: query.data?.data.pagination.count,
            position: ['bottomCenter'],
            onChange(p, ps) {
              if (pageSize !== ps) setPage(INITIAL_PAGE)
              else setPage(p)
              setPageSize(ps)
            },
            showTotal: (total, range) => {
              return `${t('general.showing')} ${range[0]} - ${range[1]} ${t(
                'general.from'
              )} ${total} ${t('general.items')}`
            },
          }}
        />
      </Card>
    </>
  )
}

export default OrdersPage
